
import React, { useEffect, useState, useRef } from 'react';

import i18n from 'i18next';
import '../../../../lang/i18n';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import { questionnaireAtom } from '../../../../jotai_state';

import Card, {
    CardBody,
    CardFooter,
    CardHeader,
    FeedbackCard
} from '../../../../components/CustomCard';

const emmaIcon = new URL('/public/emma-avatar.png', import.meta.url).toString();
import calendarIcon from '../../../../../assets/images/icons/calendar.svg';
import taskIcon from '../../../../../assets/images/icons/task.svg';
import learningIcon from '../../../../../assets/images/icons/learning.svg';
import letterIcon from '../../../../../assets/images/icons/letter.svg';
import profileIcon from '../../../../../assets/images/icons/profile.svg';

import { Outlet, useLocation, useNavigate } from 'react-router-dom';

const FeedbackResultPage = () => {


    const translation = useTranslation();
    const { t } = translation;
    const currentLang = i18n.language || 'en';

    const location = useLocation();
    const navigate = useNavigate();

    // get id from url
    const id = location.pathname.split('/').pop();

    const isRoot = location.pathname === `/feedback/result/${id}`;

    const [isFirstRender, setIsFirstRender] = useState(true);
    const [feedbackData, setFeedbackData] = useState({
        type: "task",
        title: "Task 1",
        created_at: new Date().toLocaleDateString(currentLang, {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        }),
        feedbacks: {
            received: 4,
            total: 6
        },
        respondents: ["Emma", "John", "Doe", "Jane", "Carl", "Alice", "Bob", "Eve", "Mallory", "Trent", "Eve", "Mallory", "Trent"],
        questions: [
            {
                description: "How do you feel about the new feature?",
                answers: [
                    {
                        answer: 1,
                        count: 2
                    },
                    {
                        answer: 2,
                        count: 1
                    },
                    {
                        answer: 3,
                        count: 1
                    },
                    {
                        answer: 4,
                        count: 0
                    },
                    {
                        answer: 5,
                        count: 0
                    }
                ]
            },
            {
                description: "How do you feel about the new feature?",
                answers: [
                    {
                        answer: 1,
                        count: 2
                    },
                    {
                        answer: 2,
                        count: 1
                    },
                    {
                        answer: 3,
                        count: 1
                    },
                    {
                        answer: 4,
                        count: 0
                    },
                    {
                        answer: 5,
                        count: 0
                    }
                ]
            },
            {
                description: "How do you feel about the new feature?",
                answers: [
                    {
                        answer: 1,
                        count: 2
                    },
                    {
                        answer: 2,
                        count: 1
                    },
                    {
                        answer: 3,
                        count: 1
                    },
                    {
                        answer: 4,
                        count: 0
                    },
                    {
                        answer: 5,
                        count: 0
                    }
                ]
            },
            {
                description: "How do you feel about the new feature?",
                answers: [
                    {
                        answer: 1,
                        count: 2
                    },
                    {
                        answer: 2,
                        count: 1
                    },
                    {
                        answer: 3,
                        count: 1
                    },
                    {
                        answer: 4,
                        count: 0
                    },
                    {
                        answer: 5,
                        count: 0
                    }
                ]
            },
            {
                description: "How do you feel about the new feature?",
                answers: [
                    {
                        answer: 1,
                        count: 2
                    },
                    {
                        answer: 2,
                        count: 1
                    },
                    {
                        answer: 3,
                        count: 1
                    },
                    {
                        answer: 4,
                        count: 0
                    },
                    {
                        answer: 5,
                        count: 0
                    }
                ]
            }
        ]
    });

    const scrollableDivRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (isFirstRender) {
            setIsFirstRender(false);
        } else {

        }
    }, [isFirstRender]);


    if (!isRoot) {
        return <Outlet />
    }

    return (
        <div className="flex flex-col h-full flex-grow min-h-0 gap-6 pr-2">
            <h1 className="text-2xl font-medium text-white">
                {feedbackData.type === "task" || "focus" ? t("your-feedback-on") : t("your-feedback")} {feedbackData.title}
            </h1>

            {/* Questionaire info */}
            <div className="flex flex-col gap-3">
                <div className="flex items-start gap-1">
                    <img src={calendarIcon} alt="calendar icon" className="w-6 h-6" />
                    <p className="text-base font-normal text-white">

                        {t("created-at")} {feedbackData.created_at}
                    </p>
                </div>
                <div className="flex items-start gap-1">
                    <img src={letterIcon} alt="letter icon" className="w-6 h-6" />
                    <p className="text-base font-normal text-white">
                        {t("received")} {feedbackData.feedbacks.received} {t("feedback-out-of")} {feedbackData.feedbacks.total}
                    </p>
                </div>
                <div className="flex items-start gap-1">
                    <img src={profileIcon} alt="learning icon" className="w-6 h-6" />
                    <p className="text-base font-normal text-white leading-tight">
                        {feedbackData.respondents.join(", ")}
                    </p>
                </div>
            </div>

            {/* Answers */}
            <div className="">
                {feedbackData.questions.map((question, index) => {
                    // Calculate total responses for this question
                    const totalResponses = question.answers.reduce((sum, answer) => sum + answer.count, 0);

                    return (
                        <React.Fragment key={index}>
                            {/* Title */}
                            <h2 className="text-lg font-medium text-white mb-2">
                                {t("question")} {index + 1}
                            </h2>

                            {/* Description card */}
                            <div className="p-4 bg-neutral-700 text-neutral-400 rounded-lg mb-3">
                                {question.description}
                            </div>

                            {/* Answers horizontal bar chart */}
                            <div className="w-full flex flex-col gap-4">
                                {question.answers.map((item, idx) => {
                                    // Calculate percentage for this answer
                                    const percentage = totalResponses > 0 ? (item.count / totalResponses) * 100 : 0;

                                    return (
                                        <div key={`answer-${idx}`} className="">
                                            {/* Label and Value Section */}
                                            <div className="flex justify-between items-center mb-2">
                                                <div className="text-white text-sm font-medium">{item.answer}</div>
                                                <div className="flex items-center">
                                                    <img src={profileIcon} alt="profile icon" className="w-4 h-4" />
                                                    <span className="ml-2 text-white font-medium">
                                                        {item.count} ({percentage.toFixed(1)}%)
                                                    </span>
                                                </div>
                                            </div>
                                            {/* Bar Section */}
                                            <div className="w-full bg-neutral-600 rounded-full h-2">
                                                <div
                                                    className="bg-primary-500 h-2 rounded-full"
                                                    style={{ width: `${percentage}%` }}
                                                ></div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>

                            {feedbackData.questions.length - 1 !== index && (
                                <hr className="border-neutral-600 my-6" />
                            )}
                        </React.Fragment>
                    );
                })}

            </div>


            {/* Question cards */}
            {/* <div ref={scrollableDivRef} className="flex-1 overflow-y-auto flex flex-col pb-1 pr-2 gap-6">
                {feedbackData.answers.map((answer, index) => {
                    return (

                    )
                })}
            </div> */}

        </div>
    );
}

export default FeedbackResultPage;
