
import React, { useEffect, useState, useRef } from 'react';
import '../../lang/i18n';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import { questionnaireAtom } from '../../jotai_state';

import Card, {
    CardBody,
    CardFooter,
    CardHeader,
    FeedbackCard
} from '../../components/CustomCard';

const emmaIcon = new URL('/public/emma-avatar.png', import.meta.url).toString();
import calendarIcon from '../../../assets/images/icons/calendar.svg';
import taskIcon from '../../../assets/images/icons/task.svg';
import learningIcon from '../../../assets/images/icons/learning.svg';

import { Outlet, useLocation, useNavigate } from 'react-router-dom';

const FeedbackPage = () => {


    const translation = useTranslation();
    const { t } = translation;

    const location = useLocation();
    const navigate = useNavigate();

    const isRoot = location.pathname === '/feedback';

    const [isFirstRender, setIsFirstRender] = useState(true);
    const [responses, setResponses] = useState([
        {
            id: 1,
            title: "Some task",
            type: "task",
            results: [
                {
                    id: 1,
                    title: "2021-09-01",
                    icon: calendarIcon
                }
            ]
        },
        {
            id: 2,
            title: "Some development area",
            type: "development_area",
            results: [
                {
                    id: 1,
                    title: "2021-09-01",
                    icon: calendarIcon
                },

                {
                    id: 2,
                    title: "2021-09-02",
                    icon: calendarIcon
                },
                {
                    id: 3,
                    title: "2021-09-03",
                    icon: calendarIcon
                }
            ]
        }
    ]);

    const scrollableDivRef = useRef<HTMLDivElement>(null);



    useEffect(() => {
        if (isFirstRender) {
            setIsFirstRender(false);
        } else {

        }
    }, [isFirstRender]);


    if (!isRoot) {
        return <Outlet />
    }

    return (
        <div className="flex flex-col h-full flex-grow min-h-0 gap-6 mt-6">
            <h1 className="text-2xl font-medium text-white">
                {t("create-feedback")}
            </h1>

            {/* Question cards */}
            <div ref={scrollableDivRef} className="flex-1 flex flex-col pb-1 pr-2 gap-6">
                {responses.map((response, index) => (
                    <FeedbackCard
                        key={index}
                        title={response.title}
                        items={response.results.map(result => ({
                            title: result.title,
                            icon: result.icon,
                            onClick: () => navigate(`/feedback/result/${response.id}`)
                        }))}
                        leftIcon={response.type === "task" ? taskIcon : learningIcon}
                    />
                ))}
            </div>

        </div>
    );
}

export default FeedbackPage;