
// CardFooter.tsx
import React from 'react';

interface CardFooterProps {
  children: React.ReactNode;
  className?: string;
}

const CardFooter = ({ children, className = '' }: CardFooterProps) => (
  <div className={`border-neutral-600 border-t rounded-b-xl p-4 ${className}`}>
    {children}
  </div>
);

export default CardFooter;
