import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
    resources: {
        en: {
            translation: {
                "start": "Start",
                "invalid-email": "Invalid email address. Please enter a correct email address",
                "next": "Next",
                'deadline': 'Deadline',
                "continue": "Continue",
                "time-ago-now": "now",
                "send": "Send",
                "chat-placeholder": "Type your message here...",
                "chat-send": "Send",
                "chat-start": "Get started",
                "chat": "Chat",
                "menu-chat": "Chat with Emma",
                "menu-my-tasks": "My tasks",
                "menu-development-plan": "My development plan",
                "menu-my-account": "My account",
                "menu-support": "Support",
                "menu-about-company": "About Zebrain",
                "task": "Task",
                "description": "Description",
                "logout": "Log out",
                "tasks-page-title": "Select task to work on",
                "auto-task-chat-message": "I want to work on the task",
                "development-area-page-title": "Select focus area",
                "focus-area-quick-select": "Quick select",
                "focus-area-start-chat": "I want to start coaching within the focus area",
                "focus-area-start-chat-button": "Start coaching session",
                "focus-area-learn-more-button": "I want to learn more about the topic",
                "chat-nav-title": "Chat",
                "tasks-nav-title": "My tasks",
                "ongoing": "Ongoing",
                "completed": "Completed",
                "completed-tasks": "Completed tasks",
                "development-plan-nav-title": "My development plan",
                "focus-area-nav-title": "Focus Area",
                "no-tasks": "You have not created any tasks.",
                "no-development-areas": "You have not created any focus areas.",
                "chat-welcome": "Hi, nice to meet you here!\nI'm your AI coach Emma, let's start with a short introduction.",
                "support-nav-title": "Support",
                "support-page-title": "What can we help you with?",
                "support-description-placeholder": "Describe your issue here...",
                "support-email-info": "You can also email us at",
                "support-modal-success-title": "Thank you for reaching out to us! ",
                "support-modal-success-description": "We will get back to you as soon as possible.",
                "support-error-message": "An error occurred, please try again later.",
                "about-nav-title": "About Zebrain",
                "feedback-nav-title": "Feedback",
                "menu-feedback": "Feedback",
                "request-feedback": "Request feedback",
                "received-feedback": "Received feedback",
                "choose-feedback": "Choose what to get feedback on",
                "create-feedback": "Create feedback",
                "feedback-select-recipients": "Select recipients",
                "feedback-recipients": "Recipients",
                "feedback-recipients-add": "Add recipients",
                "feedback-results": "Feedback results",
                "other-subject": "Other subject",
                "create-review-question": "Create review question",
                "feedback-create-summary": "Summary",
                "feedback-regenerate-question": "Regenerate question",
                "feedback-input-placeholder": "Write your question here...",
                "feedback-add-question": "Add question",
                "select-all": "Select all",
                "send-request": "Send request",
                "feedback-change-question": "Describe how you would like to change the question",
                "feedback-review-questions": "Review questions",
                "feedback-request-sent": "Request sent",
                "feedback-summary-modal-body": "Your feedback request is sent. You will get a notification when you recieve feedback on Feedback page.",
                "feedback-recipients-alert": "Select a minimum of three participants",
                "received": "Received",
                "feedback-out-of": "feedback out of",
                "question": "Question",
                "created-at": "Created at",
                "respondents": "Respondents",
                "your-feedback-on": "Your feedback",
                "asking-for-feedback": "is asking for your feedback",
                "strongly-agree": "Strongly agree",
                "agree": "Agree",
                "neutral": "Neutral",
                "disagree": "Disagree",
                "strongly-disagree": "Strongly disagree",

            },
        },
        sv: {
            translation: {
                "start": "Start",
                "invalid-email": "Ogiltig e-postadress. Ange en korrekt e-postadress",
                "next": "Nästa",
                'deadline': 'Slutdatum',
                "continue": "Fortsätt",
                "send": "Skicka",
                "time-ago-now": "nu",
                "chat-placeholder": "Skriv ditt meddelande här...",
                "chat-send": "Skicka",
                "chat-start": "Kom igång",
                "chat": "Chatt",
                "menu-chat": "Chatta med Emma",
                "menu-my-tasks": "Mina uppgifter",
                "menu-development-plan": "Min utvecklingsplan",
                "menu-my-account": "Mitt konto",
                "menu-support": "Support",
                "menu-about-company": "Om Zebrain",
                "task": "Uppgift",
                "description": "Beskrivning",
                "logout": "Logga ut",
                "tasks-page-title": "Välj uppgift att arbeta med",
                "auto-task-chat-message": "Jag vill arbeta med uppgiften",
                "development-area-page-title": "Välj fokusområde",
                "focus-area-quick-select": "Snabbval",
                "focus-area-start-chat": "Jag vill starta coaching inom fokusområdet",
                "focus-area-start-chat-button": "Starta coachsession",
                "focus-area-learn-more-button": "Jag vill lära mig mer om ämnet",
                "chat-nav-title": "Chatt",
                "tasks-nav-title": "Mina uppgifter",
                "ongoing": "Pågående",
                "completed": "Färdiga",
                "completed-tasks": "Färdiga uppgifter",
                "development-plan-nav-title": "Min utvecklingsplan",
                "focus-area-nav-title": "Fokusområde",
                "no-tasks": "Du har inte skapat några uppgifter.",
                "no-development-areas": "Du har inte skapat några fokusområden.",
                "chat-welcome": "Hej, vad kul att träffa dig här!\nJag är din AI coach Emma, låt oss börja med en kort introduktion.",
                "support-nav-title": "Support",
                "support-page-title": "Vad kan vi hjälpa dig med?",
                "support-description-placeholder": "Beskriv ditt problem här...",
                "support-email-info": "Du kan också mejla oss på",
                "support-modal-success-title": "Tack för att du kontaktar oss! ",
                "support-modal-success-description": "Vi återkommer så snart som möjligt.",
                "support-error-message": "Ett fel uppstod, försök igen senare.",
                "about-nav-title": "Om Zebrain",
                "menu-feedback": "Återkoppling",
                "feedback-nav-title": "Återkoppling",
                "request-feedback": "Begär återkoppling",
                "received-feedback": "Mottagen återkoppling",
                "create-feedback": "Skapa återkoppling",
                "feedback-select-recipients": "Välj mottagare",
                "feedback-recipients": "Mottagare",
                "feedback-recipients-add": "Lägg till mottagare",
                "feedback-results": "Återkopplingsresultat",
                "choose-feedback": "Välj vad du vill få återkoppling på",
                "other-subject": "Övrigt ämne",
                "create-review-question": "Skapa återkopplingsfråga",
                "feedback-create-summary": "Sammanfattning",
                "feedback-regenerate-question": "Omgenerera fråga",
                "feedback-input-placeholder": "Skriv din fråga här...",
                "feedback-add-question": "Lägg till fråga",
                "select-all": "Välj alla",
                "send-request": "Skicka förfrågan",
                "feedback-change-question": "Beskriv hur du vill ändra frågan",
                "feedback-review-questions": "Granska frågor",
                "feedback-request-sent": "Förfrågan skickad",
                "feedback-summary-modal-body": "Din återkopplingsförfrågan är skickad. Du kommer få en notifikation när du får återkoppling på Återkopplingssidan.",
                "feedback-recipients-alert": "Välj minst tre deltagare",
                "received": "Mottagit",
                "feedback-out-of": "återkopplingar av",
                "question": "Fråga",
                "created-at": "Skapad den",
                "respondents": "Deltagare",
                "your-feedback-on": "Din återkoppling på",
                "your-feedback": "Din återkoppling",
                "asking-for-feedback": "ber om din återkoppling",
                "strongly-agree": "Håller helt med",
                "agree": "Håller med",
                "neutral": "Neutral",
                "disagree": "Håller inte med",
                "strongly-disagree": "Håller inte alls med",
            },
        },
    },
    fallbackLng: "en",
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;