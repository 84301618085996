import React from 'react'
import Modal from '../../../../components/Modal';
import ModalHeader from '../../../../components/Modal/components/ModalHeader';
import ModalBody from '../../../../components/Modal/components/ModalBody';

import '../../../../lang/i18n';
import { useTranslation } from 'react-i18next';

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const SupportModal = (props: ModalProps) => {

    const { isOpen, onClose } = props;

    const translation = useTranslation();
    const { t, i18n } = translation;

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalHeader onClose={onClose}>
                <h3 className="text-lg text-white font-medium">{t('support-modal-success-title')} </h3>
            </ModalHeader>
            <ModalBody>
                <p>{t('support-modal-success-description')}</p>
            </ModalBody>
        </Modal>
    );


    return (
        <div id="hs-basic-modal" className="shadow-custom-shadow hs-overlay hs-overlay-open:opacity-100 hs-overlay-open:duration-500 hidden fixed inset-0 z-[80] flex items-center justify-center opacity-0 transition-all pointer-events-none">
            {/* Backdrop */}
            <div className="fixed inset-0 bg-gray-900 bg-opacity-50 dark:bg-opacity-80 hs-overlay-backdrop"></div>
            
            {/* Modal content */}
            <div className="sm:max-w-lg sm:w-full m-3 relative z-[90]">
                <div className="flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto dark:bg-neutral-800 dark:border-neutral-700 dark:shadow-neutral-700/70">
                    <div className="flex justify-between items-center p-4 border-b dark:border-neutral-600">
                        <h3 className="text-lg font-medium text-gray-800 dark:text-white">
                            {title}
                        </h3>
                        <button type="button" className="flex justify-center items-center size-7 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-neutral-700" data-hs-overlay="#hs-basic-modal">
                            <span className="sr-only">Close</span>
                            <img src={closeIcon} alt="menu" />
                        </button>
                    </div>
                    <div className="p-4 overflow-y-auto">
                        <p className="mt-1 text-gray-800 dark:text-neutral-400">
                            {description}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SupportModal