
import React, { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import '../../../../lang/i18n';
import { useTranslation } from 'react-i18next';

import CustomButton from '../../../../components/CustomButton';

const AcceptInvitePage = () => {
    const { code } = useParams(); // Extract invite code from URL
    const navigate = useNavigate(); // Hook to navigate programmatically
    const { t } = useTranslation(); // i18n translation hook
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [data, setData] = useState({});
    const [showFeedbackSent, setShowFeedbackSent] = useState(false);

    // Define standard agree/disagree options
    const agreeOptions = [
        'Strongly Disagree',
        'Disagree',
        'Neutral',
        'Agree',
        'Strongly Agree'
    ];

    useEffect(() => {
        const getData = async () => {
            try {
                setLoading(true);
                const response = await fetch("https://feedback-api.dazzle.zebrain.se/questionaire/dFDL7TdQ?token=7b22656d61696c223a226578616d706c65406578616d706c652e636f6d222c2268617368223a2263663631363366376534623438356364333532653334353032383439626236346637313763313134633161343436313434303362333562636335396339373635227d", {
                    method: 'GET',
                });

                if (!response.ok) {
                    if (response.status === 404) {
                        throw new Error('Invite code is invalid or has expired. Please check your code and try again.');
                    }
                    throw new Error(`Error: ${response.statusText}`);
                }

                const data = await response.json();
                setData(data);
                // setData({
                //     "id": 1,
                //     "type": "agree_scalar",
                //     "origin": "task",
                //     "name": "John Doe",
                //     "responses": 3,
                //     "total_respondents": 6,
                //     "deadline": "2021-12-31T23:59:59Z",
                //     "anonymous": true,
                //     "structure": [
                //         {
                //             "id": 1,
                //             "text": "What do you think about the overall design of the website?",
                //         },
                //         {
                //             "id": 2,
                //             "text": "How easy was it to find the information you were looking for?",
                //         },
                //         {
                //             "id": 3,
                //             "text": "What features would you like to see added to the website?",
                //         }
                //     ]
                // });
                console.log('Questionaire:', data);
            } catch (err) {
                console.error('Failed to accept invite:', err);
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        getData();
    }, [code]);

    if(loading || !data || Object.keys(data).length === 0) {
        return <div>Loading...</div>;
    }

    if(showFeedbackSent) {
      return (
        <div className="flex flex-col h-full flex-grow min-h-0 gap-6 mt-6">

        </div>
      );
    }

    return (
      <div className="flex flex-col h-full flex-grow min-h-0 gap-6">
        <h1 className="text-2xl font-medium dark:text-white mt-6">
          {data.name} {t('asking-for-feedback')}
        </h1>
        <div className="text-neutral-400">
          <ul className="list-disc list-inside">
            <li>5/10 recipients have submitted feedback.</li>
            <li>This feedback will close on {data.deadline}</li>
            <li>All responses will remain anonymous</li>
          </ul>
        </div>
        <hr className="border-neutral-600" />
        <div className="">
          {data.structure.map((question, index) => (
            <div key={question.id} className="mb-6">
              <h2 className="text-lg font-medium text-white">
                {`Question ${index + 1}`}
              </h2>
              <p className="text-neutral-400 mt-2">
                {question.text}
              </p>
              {/* Answer options */}
              <div className="mt-4">
                <div className="flex flex-col gap-y-3 ">
                  {agreeOptions.map((option, idx) => (
                    <label key={idx} className="flex items-center">
                      <input
                        type="radio"
                        name={`question-${question.id}`}
                        className="sr-only peer"
                      />
                      <div className="
                        w-6 h-6
                        rounded-full
                        border border-white
                        bg-transparent
                        relative
                        cursor-pointer
                        peer-checked:border-primary-500
                        peer-focus:outline-none
                        after:content-['']
                        after:absolute after:inset-1
                        after:rounded-full
                        after:bg-transparent
                        peer-checked:after:bg-primary-500
                      "></div>
                      <span className="ml-2 text-sm text-gray-500 dark:text-neutral-400">{option}</span>
                    </label>
                  ))}
                </div>
              </div>
              <hr className="border-neutral-600 mt-4" />
            </div>
          ))}
        </div>
        <CustomButton>
          Submit
        </CustomButton>
      </div>

    )
};

export default AcceptInvitePage;