
import React, { useEffect, useState, useRef } from 'react';
import '../../../../lang/i18n';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import { questionnaireAtom } from '../../../../jotai_state';
import { useAuth0 } from '@auth0/auth0-react';

import plusIcon from '../../../../../assets/images/icons/plus.svg';
import emailIcon from '../../../../../assets/images/icons/email.svg';
import closeIcon from '../../../../../assets/images/icons/close.svg';

import CustomButton from '../../../../components/CustomButton';
import Input from '../../../../components/Input';
import Checkbox from '../../../../components/Checkbox';
import Modal from '../../../../components/Modal';

const emmaIcon = new URL('/public/emma-avatar.png', import.meta.url).toString();

import { Outlet, useLocation, useNavigate } from 'react-router-dom';

const CreateFeedbackSummaryPage = () => {
    const { getAccessTokenSilently, logout: auth0logout } = useAuth0();
    const translation = useTranslation();
    const { t } = translation;

    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');
    const isRoot = location.pathname === '/tasks/feedback/create/recipients';

    const [questionnaire, setQuestionnaire] = useAtom(questionnaireAtom);

    const [isFirstRender, setIsFirstRender] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const answerOptions = [
        { id: 1, text: t("strongly-disagree") },
        { id: 2, text: t("disagree") },
        { id: 3, text: t("neutral") },
        { id: 4, text: t("agree") },
        { id: 5, text: t("strongly-agree") },
    ];

    useEffect(() => {
        if (isFirstRender) {
            setIsFirstRender(false);
        } else {
            if (questionnaire.structure.length === 0) {
                // If there are recipients, navigate to the next page
                navigate(`/tasks/feedback/create`);
            } else if (questionnaire.recipients.length === 0) {
                // If there are no recipients, navigate to the previous page
                navigate(`/tasks/feedback/create/recipients`);
            }
        }
    }, [isFirstRender]);

    const removeQuestion = (id) => {
        setQuestionnaire({
            ...questionnaire,
            structure: questionnaire.structure.filter((question) => question.id !== id),
        });
    }

    const removeRecipient = (email) => {
        setQuestionnaire({
            ...questionnaire,
            recipients: questionnaire.recipients.filter((recipient) => recipient.email !== email),
        });
    }

    const sendFeedbackRequest = async () => {
        // Go to next page if at least one question has been created and is not loading
        const body = JSON.stringify({
            origin: {
                type: questionnaire.origin.type,
                content: questionnaire.origin.content,
            },
            structure: questionnaire.structure,
            feedback_users: questionnaire.recipients.map((recipient) => recipient.email),
            deadline: "2025-12-31T23:59:59Z",
        })

        console.log("------- Body:", body);

        if (questionnaire.structure.length > 0 && questionnaire.recipients.length > 0) {
            try {
                let token = await getAccessTokenSilently();
                const response = await fetch('https://feedback-api.dazzle.zebrain.se/questionaire', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: body,
                });
    
                if (!response.ok) {
                    throw new Error('Failed to fetch user data');
                }
    
                const questionData = await response.json();
                console.log("Question data:", questionData);
    
            } catch (error) {
                console.error('Failed to fetch user data', error);
            }

            // Make request to backend
            setIsModalOpen(true);
        }
    }

    return (
        <div className="flex flex-col h-full flex-grow min-h-0 gap-6 pr-2">
            <h1 className="text-2xl font-medium dark:text-white">
                {t("feedback-create-summary")}
            </h1>

            <div className="flex-1 flex flex-col gap-6">
                {/* Questions */}
                <div >
                    <h2 className="text-lg font-medium dark:text-white mb-3">
                        {t("feedback-review-questions")}
                    </h2>
                    <div className="flex-1  gap-3 flex flex-col pb-1 px-[1px]">
                        {questionnaire.structure.map((question, index) => {
                            return (
                                <div key={question.id} className="flex flex-col gap-6">
                                    <div className="flex flex-row gap-4 bg-neutral-800 rounded-lg p-4">
                                        {/* Question info */}
                                        <div className="flex flex-col gap-4 grow">
                                            {/* Question description */}
                                            <div>
                                                <p className="text-neutral-400 text-base font-normal">
                                                    {question.description}
                                                </p>
                                            </div>

                                            {/* Answer input */}
                                            <div className="flex flex-col gap-2">
                                                {question.type === "agreement_scale" && answerOptions.map((option) => (
                                                    <div className="flex" key={`question-${option.id}`}>
                                                        <div className="flex flex-col justify-center">
                                                            <input
                                                                type="checkbox"
                                                                className="bg-neutral-800 border-neutral-400 rounded text-blue-600 focus:ring-none h-6 w-6"
                                                                id={option.id}
                                                                disabled={true}
                                                            />
                                                        </div>
                                                        <label
                                                            htmlFor={String(option.id)}
                                                            className="text-base font-normal text-neutral-400 ms-2"
                                                        >
                                                            {option.text}
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        {/* Delete question */}
                                        <div>
                                            <img
                                                src={closeIcon}
                                                alt="Close"
                                                className="cursor-pointer h-4 w-4"
                                                onClick={() => removeQuestion(question.id)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>

                {/* Recipients */}
                <div>
                    <h2 className="text-lg font-medium dark:text-white mb-3">
                        {t("feedback-recipients")}
                    </h2>
                    <div className="flex-1 gap-3 flex flex-col pb-1 px-[1px]">
                        {questionnaire.recipients.map((recipient, index) => {
                            return (
                                <div>
                                    <span className="inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-lg text-xs font-medium bg-neutral-700 text-neutral-300">
                                        {recipient.name || recipient.email}
                                        <button
                                            type="button"
                                            className="shrink-0 size-4 inline-flex items-center justify-center rounded-full focus:outline-none"
                                            onClick={() => removeRecipient(recipient.email)}
                                        >
                                            <span className="sr-only">Remove badge</span>
                                            <svg className="shrink-0 size-3" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                                <path d="M18 6 6 18"></path>
                                                <path d="m6 6 12 12"></path>
                                            </svg>
                                        </button>
                                    </span>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>

            {/* Buttons */}
            <CustomButton
                onClick={() => sendFeedbackRequest()}
                extraStyle="w-full"
                disabled={(questionnaire.structure.length === 0 || questionnaire.recipients.length === 0)}
            >
                {t("send-request")}
            </CustomButton>
            < Modal isOpen={isModalOpen} onClose={() => {setIsModalOpen(false) }} title={t("feedback-request-sent")} body={t(t("feedback-summary-modal-body"))} />
        </div>
    );
}

export default CreateFeedbackSummaryPage;