// InputComponent.tsx
import React, { useState } from 'react';

interface InputComponentProps {
  value: string;
  onError: (error: string) => void;
  onChange: (value: string, isValid: boolean) => void;
  placeholder: string;
  errorText?: string;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  validators?: Array<(text: string) => { isValid: boolean; error: string }>;
}

const InputComponent = ({
  value,
  onChange,
  placeholder,
  leftIcon = null,
  rightIcon = null,
  validators = [],
  onError = () => {},
  ...props
}: InputComponentProps) => {
  const hasLeftIcon = Boolean(leftIcon);
  const hasRightIcon = Boolean(rightIcon);

  const paddingLeft = hasLeftIcon ? 'ps-12' : 'ps-4';
  const paddingRight = hasRightIcon ? 'pe-12' : 'pe-4';

  const [error, setError] = useState('');

  const inputClasses = `
    py-3 ${paddingLeft} ${paddingRight} block w-full bg-neutral-900 text-white rounded-lg
    resize-none overflow-y-auto max-h-40 border-none ring-1 ring-neutral-600 focus:outline-none 
    focus:ring-2 focus:ring-primary-500
  `;

  const handleChange = (event) => {
    const newValue = event.target.value;
    let isValid = true;
    let errorMessage = '';

    validators.forEach((validator) => {
      const { isValid: valid, error } = validator(newValue);
      if (!valid) {
        isValid = false;
        errorMessage = error;
      }
    });

    setError(errorMessage);
    onChange(newValue, isValid);
  };

  return (
    <>
      <div className="relative">
        <textarea
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          className={inputClasses}
          style={{
            whiteSpace: 'pre-wrap',
            overflowWrap: 'break-word',
          }}
          rows={1}
        />

        {hasLeftIcon && (
          <div className="absolute inset-y-0 left-0 flex items-center ps-4 pointer-events-none">
            {leftIcon}
          </div>
        )}

        {hasRightIcon && (
          <div className="absolute inset-y-0 right-0 flex items-center pe-4">
            {rightIcon}
          </div>
        )}
      </div>

      {error && <p className="text-cherry-500">{error}</p>}
    </>
  );
};

export default InputComponent;
