import React from 'react';
import Modal from '../../../../components/Modal';
import ModalHeader from '../../../../components/Modal/components/ModalHeader';
import ModalBody from '../../../../components/Modal/components/ModalBody';
import ModalFooter from '../../../../components/Modal/components/ModalFooter';

import Button from '../../../../components/CustomButton';

import '../../../../lang/i18n';
import { useTranslation } from 'react-i18next';

const FeedbackModal = ({ isOpen, onClose, onModalStartClick }) => {

    const translation = useTranslation();
    const { t, i18n } = translation;

    const isSwedish = i18n.language === 'sv';


    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalHeader onClose={onClose}>
                <h3 className="text-lg text-white font-medium">Feedback</h3>
            </ModalHeader>
            <ModalBody>
                <>
                    <p className="text-medium text-base">{isSwedish ? 'Steg 1:' : 'Step 1:'}</p>
                    <p className="text-normal text-base">
                        {isSwedish
                            ? 'Din uppgift skapas av AI till en granskningsfråga, som du kan ändra för att passa dina önskemål.'
                            : 'Your task is AI-generated into a review question, which you can modify to suit your preferences.'}
                    </p>
                    <br />
                    <p className="text-medium text-base">{isSwedish ? 'Steg 2:' : 'Step 2:'}</p>
                    <p className="text-normal text-base">
                        {isSwedish
                            ? 'Lägg till mottagare och skicka din återkopplingsförfrågan.'
                            : 'Add recipients and send your feedback request.'}
                    </p>
                    <br />
                    <p className="text-normal text-base">
                        {isSwedish
                            ? 'Mottagen återkoppling kommer att visas på feedbacksidan, vi meddelar dig när svaren kommer.'
                            : 'Recieved feedback will appear on the Feedback page, we\'ll notify you when responses arrive.'}
                    </p>
                </>
            </ModalBody>
            <ModalFooter>
                <Button onClick={onModalStartClick} >
                    {t('start')}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default FeedbackModal;