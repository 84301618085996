// FeedbackRecipientsPage.tsx

import React, { useEffect, useState } from 'react';
import '../../../../lang/i18n';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import { questionnaireAtom } from '../../../../jotai_state';
import { useLocation, useNavigate, Outlet } from 'react-router-dom';

import plusIcon from '../../../../../assets/images/icons/plus.svg';
import emailIcon from '../../../../../assets/images/icons/email_16x16.svg';
import closeIcon from '../../../../../assets/images/icons/close.svg';
import alertIcon from '../../../../../assets/images/icons/red/alert.svg';

import CustomButton from '../../../../components/CustomButton';
import Input from '../../../../components/Input';
import Checkbox from '../../../../components/Checkbox';

const FeedbackRecipientsPage = () => {
  const { t } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  const isRoot = location.pathname === '/tasks/feedback/create/recipients';

  const [questionnaire, setQuestionnaire] = useAtom(questionnaireAtom);

  const [isFirstRender, setIsFirstRender] = useState(true);

  const [existingContacts, setExistingContacts] = useState([
    {
      name: 'Samantha',
      email: 'example@example.com',
    },
    {
      name: 'John',
      email: 'example1@example.com',
    },
    {
      name: 'Joanna',
      email: 'example2@example.com',
    },
    {
      name: 'Cole',
      email: 'example3@example.com',
    },
  ]);

  const [newRecipients, setNewRecipients] = useState([]);
  const [showAlert, setShowAlert] = useState(false); // Added state for alert

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    } else {
      if (questionnaire.structure.length === 0) {
        navigate(`/tasks/feedback/create`);
      }
    }
  }, [isFirstRender]);

  // Initialize newRecipients from questionnaire.recipients on mount
  useEffect(() => {
    // Filter out existing contacts from questionnaire.recipients to get new recipients
    const nonExistingRecipients = questionnaire.recipients.filter(
      (recipient) =>
        !existingContacts.some((contact) => contact.email === recipient.email)
    );

    // Map the non-existing recipients to the newRecipients state with isValid set to true
    setNewRecipients(
      nonExistingRecipients.map((recipient) => ({
        ...recipient,
        isValid: true,
      }))
    );
  }, [existingContacts, questionnaire.recipients]);

  useEffect(() => {
    console.log('Questionnaire recipients:', questionnaire.recipients);
  }, [questionnaire.recipients]);

  // Hide alert when enough recipients are selected
  useEffect(() => {
    if (questionnaire.recipients.length >= 3 && showAlert) {
      setShowAlert(false);
    }
  }, [questionnaire.recipients.length, showAlert]);

  const ShowNewRecipientInput = () => {
    setNewRecipients((prev) => [
      ...prev,
      {
        name: '',
        email: '',
        isValid: false,
      },
    ]);
  };

  const goToNextPage = () => {
    if (questionnaire.structure.length > 0) {
      const isLoading = questionnaire.structure.some(
        (question) => question.isLoading
      );
      if (!isLoading) {
        if (questionnaire.recipients.length >= 3) {
          navigate(`/tasks/feedback/create/recipients/summary`);
        } else {
          setShowAlert(true);
        }
      }
    }
  };

  const emailValidator = (text) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return {
      isValid: emailRegex.test(text),
      error: t('invalid-email'),
    };
  };

  if (!isRoot) {
    return <Outlet />;
  }

  // Compute whether all existing contacts are selected
  const allContactsSelected = existingContacts.every((contact) =>
    questionnaire.recipients.some(
      (recipient) => recipient.email === contact.email
    )
  );

  return (
    <div className="flex flex-col h-full flex-grow min-h-0 gap-6">
      <h1 className="text-2xl font-medium dark:text-white">
        {t('feedback-select-recipients')}
      </h1>

      {/* Alert Heading */}
      {showAlert && (
        <div className="flex items-center rounded-lg border border-cherry-500 bg-cherry-200 text-neutral-900 p-4 gap-2">
          <img src={alertIcon} alt="Alert" className="w-6 h-6" />
          <span>{t('feedback-recipients-alert')}</span>
        </div>
      )}

      {/* Select All Checkbox */}
      <div className="">
        <Checkbox
          id="selectAll"
          label={t('select-all')}
          checked={allContactsSelected}
          onChange={(checked) => {
            if (checked) {
              // Add all existingContacts to questionnaire.recipients
              setQuestionnaire((prev) => {
                const newRecipientsToAdd = existingContacts.filter(
                  (contact) =>
                    !prev.recipients.some(
                      (recipient) => recipient.email === contact.email
                    )
                );
                return {
                  ...prev,
                  recipients: [...prev.recipients, ...newRecipientsToAdd],
                };
              });
            } else {
              // Remove all existingContacts from questionnaire.recipients
              setQuestionnaire((prev) => {
                return {
                  ...prev,
                  recipients: prev.recipients.filter(
                    (recipient) =>
                      !existingContacts.some(
                        (contact) => contact.email === recipient.email
                      )
                  ),
                };
              });
            }
          }}
        />
      </div>

      {/* List of Existing Contacts */}
      <div className="flex-1 gap-2 flex flex-col pb-1 px-[1px]">
        {existingContacts.map((contact, index) => {
          if (!questionnaire) return null;
          return (
            <div key={index}>
              <Checkbox
                id={contact.email}
                label={contact.name}
                checked={questionnaire.recipients.some(
                  (recipient) => recipient.email === contact.email
                )}
                onChange={(checked) => {
                  if (checked) {
                    setQuestionnaire((prev) => ({
                      ...prev,
                      recipients: [...prev.recipients, contact],
                    }));
                  } else {
                    setQuestionnaire((prev) => ({
                      ...prev,
                      recipients: prev.recipients.filter(
                        (recipient) => recipient.email !== contact.email
                      ),
                    }));
                  }
                }}
              />
            </div>
          );
        })}
        <div className="flex flex-col gap-2 mt-4">
          {/* List of New Recipients */}
          {newRecipients.map((recipient, index) => {
            return (
              <Input
                key={index}
                label={t('recipient-email')}
                value={recipient.email}
                placeholder={'Email'}
                leftIcon={
                  <img src={emailIcon} alt="Email" className="w-6 h-6" />
                }
                onChange={(value, isValid) => {
                  setNewRecipients((prev) => {
                    return prev.map((r, i) => {
                      if (i === index) {
                        const prevEmail = r.email;
                        // Remove previous email from recipients if it was valid
                        if (r.isValid) {
                          setQuestionnaire((prevQ) => ({
                            ...prevQ,
                            recipients: prevQ.recipients.filter(
                              (recipient) => recipient.email !== prevEmail
                            ),
                          }));
                        }
                        // Add new email to recipients if valid
                        if (isValid) {
                          setQuestionnaire((prevQ) => {
                            const exists = prevQ.recipients.some(
                              (recipient) => recipient.email === value
                            );
                            if (!exists) {
                              return {
                                ...prevQ,
                                recipients: [
                                  ...prevQ.recipients,
                                  { name: '', email: value },
                                ],
                              };
                            }
                            return prevQ;
                          });
                        }
                        return {
                          ...r,
                          email: value,
                          isValid: isValid,
                        };
                      }
                      return r;
                    });
                  });
                }}
                rightIcon={
                  <img
                    src={closeIcon}
                    alt="Close"
                    className="w-6 h-6 cursor-pointer"
                    onClick={() => {
                      const emailToRemove = newRecipients[index].email;
                      setNewRecipients((prev) => {
                        return prev.filter((_, i) => i !== index);
                      });
                      setQuestionnaire((prevQ) => ({
                        ...prevQ,
                        recipients: prevQ.recipients.filter(
                          (recipient) => recipient.email !== emailToRemove
                        ),
                      }));
                    }}
                  />
                }
                validators={[emailValidator]}
              />
            );
          })}
        </div>
      </div>

      {/* Buttons */}
      <div className="flex flex-col gap-2">
        <CustomButton
          buttonType="outlineWhiteBase"
          onClick={ShowNewRecipientInput}
          extraStyle="w-full"
          extraProps={{ disabled: false }}
          leftIcon={<img src={plusIcon} alt="Add" className="w-6 h-6" />}
        >
          {t('feedback-recipients-add')}
        </CustomButton>
        <CustomButton
          onClick={() => goToNextPage()}
          extraStyle="w-full"
          extraProps={{ disabled: false }}
        >
          {t('next')}
        </CustomButton>
      </div>
    </div>
  );
};

export default FeedbackRecipientsPage;
