import React, { useState, useRef, useEffect, useContext } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkParse from 'remark-parse';
import unified from 'unified';
import rehypeStringify from 'rehype-stringify';
import { visit } from 'unist-util-visit';

import '../../lang/i18n';

import { useData } from '../../contexts/dataContext';
import { NavigationContext } from '../../contexts/NavigationContext';
import { useData as uiUseData } from '../../contexts/UIContext';

import CustomButton from '../../components/CustomButton/CustomButton';
import Breadcrumbs from '../../components/Breadcrumbs';

import chevronDown from '../../../assets/images/icons/chevronDown.svg';
import './FocusAreaPage.css'; // Import the CSS file

export default function FocusAreaPage() {
  const [isExpanded, setIsExpanded] = useState(false);
  const [maxHeight, setMaxHeight] = useState('12rem');
  const [showReadMore, setShowReadMore] = useState(false);
  const { navbarHeight, setNavbarHeight } = uiUseData();
  const [data, setData] = useState({ title: '', description: '' });
  const contentRef = useRef(null);

  const { client_id, developmentAreas, fetchDevelopmentAreas } = useData();
  const { setShowNavigation } = useContext(NavigationContext);

  const prevLocation = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();

  const translation = useTranslation();
  const { t, i18n } = translation;

  // check if mobile
  const isMobile = window.innerWidth < 636;
  console.log('navbarHeight', navbarHeight)

  const gradientClasses = "relative after:content-[''] after:absolute after:inset-x-0 after:bottom-0 after:h-8 after:bg-gradient-to-b after:from-transparent after:to-neutral-900"

  useEffect(() => {
    setShowNavigation(true);
    return () => setShowNavigation(false);
  }, [setShowNavigation]);

  useEffect(() => {
    const fetchFocusArea = async () => {
      if (data.title === '' && id && client_id) {
        if (developmentAreas.length > 0) {
          const focusArea = developmentAreas.find(area => area.id === id);
          setData(focusArea);
        } else {
          await fetchDevelopmentAreas();
        }
      }
    }
    fetchFocusArea();
  }, [developmentAreas, id, client_id]);

  useEffect(() => {
    if (contentRef.current) {
      // 12rem in pixels
      setShowReadMore(contentRef.current.scrollHeight > 192);
    }
  }, [data.description]);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
    if (!isExpanded) {
      setMaxHeight(`${contentRef.current.scrollHeight}px`);
    } else {
      setMaxHeight('12rem');
    }
  };

  // display spinner if no data
  if (data.title === '' && id && developmentAreas.length === 0) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <AiOutlineLoading3Quarters className="text-6xl animate-spin" />
      </div>
    );
  }


  return (
    <>
      <div
        className="flex flex-col gap-6 pr-4"
        style={{
          marginBottom: `${isMobile ? navbarHeight + 30 : 0}px`,
        }}
      >
        {/* TITLE */}
        <div>
          <h1 className="text-2xl font-medium dark:text-white">
            {data.title}
          </h1>
        </div>
        {/* DESCRIPTION */}
        {data.description && (
          <div className="gap-2 flex flex-col">
            <div
              ref={contentRef}
              className={`text-base font-normal dark:text-neutral-400 overflow-hidden transition-all duration-300 ${!isExpanded && showReadMore ? gradientClasses : ''}`}
              style={{ maxHeight: maxHeight }}
            >
              <div className="markdown prose dark:prose-invert prose-slate">
                <Markdown
                >
                  {data.description}
                </Markdown>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* QUICK SELECT BUTTONS*/}
      <div className="flex flex-col gap-2">
            {showReadMore && (
              <button
                onClick={toggleExpand}
                className="flex items-center text-white text-sm font-normal hover:underline justify-center"
              >
                <span className="mr-2">{isExpanded ? 'Read less' : 'Read more'}</span>
                <img src={chevronDown} alt="chevron-down" className={`transform transition-transform duration-300 ${isExpanded ? 'rotate-180' : ''}`} />
              </button>
            )}
        <h2 className="text-lg font-medium dark:text-white mb-1">
          {t('focus-area-quick-select')}
        </h2>
        <CustomButton
          buttonType='outlineWhiteBase'
          onClick={() => navigate(`/?message=${t('focus-area-start-chat')} ${data.title}`)}
          extraStyle="w-full"
        >
          {t('focus-area-start-chat-button')}
        </ CustomButton>
        <CustomButton
          buttonType='outlineWhiteBase'
          onClick={() => navigate(`/?message=${t('focus-area-learn-more-button')} ${data.title}`)}
          extraStyle="w-full"
        >
          {t('focus-area-learn-more-button')}
        </ CustomButton>
      </div>
    </>
  );
}
