// FeedbackCard.tsx
import React from 'react';
import Card from '../../../CustomCard';
import CardHeader from '../CardHeader';
import CardBody from '../CardBody';

import chevronRightIcon from '../../../../../assets/images/icons/chevronRight.svg';

interface FeedbackItem {
  label: string;
  icon?: string;
  onClick?: () => void;
}

interface Props {
  title: string;
  items: FeedbackItem[];
  leftIcon: string;
  icon?: string;
}

const FeedbackCard = ({ title, items, leftIcon, icon }: Props) => {
  return (
    <Card className="w-full">
      <CardHeader className="flex items-center px-4 py-3 bg-neutral-700 rounded-t-xl">
        {leftIcon && (
          <img src={leftIcon} alt="icon" className="w-6 h-6 mr-1.5" />
        )}
        <h3 className="text-base font-medium text-white">
          {title}
        </h3>
        {icon && (
          <div className="flex flex-col flex-grow items-end gap-2">
            <img src={icon} alt="icon" />
          </div>
        )}
      </CardHeader>
      <CardBody className="p-0 bg-neutral-800 rounded-b-xl">
        <ul className="flex flex-col divide-y divide-neutral-400 ">
          {items.map((item, index) => (
            <li key={index} className="flex ">
              <button
                type="button"
                onClick={item.onClick}
                className="flex items-center justify-between w-full p-4 text-base font-normal text-white bg-neutral-800"
              >
                <div className="flex items-center">
                  {item.icon && (
                    <img src={item.icon} alt="" className="w-6 h-6 pr-2" />
                  )}
                  <span>{item.title}</span>
                </div>
                <img
                  src={chevronRightIcon}
                  alt="chevron right"
                  className="w-4 h-4"
                />
              </button>
            </li>
          ))}
        </ul>
      </CardBody>
    </Card>
  );
};

export default FeedbackCard;
