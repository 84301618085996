import React from 'react';

interface Props {
  disabled?: boolean;
  className?: string;
  extraProps?: {};
  buttonType?: string;
  onClick?: () => void;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  children: React.ReactNode;
}

const colorClasses = {
  blue:
    'bg-primary-500 hover:bg-primary-400 active:bg-primary-400 disabled:opacity-40 dark:text-neutral-900',
  'outline-white':
    'bg-transparent text-white border-white hover:bg-white hover:text-neutral-900 disabled:opacity-40',
};

const sizeClasses = {
  sm: 'py-2 px-3',
  md: 'py-3 px-4 text-button-md font-medium',
};

const buttonTypeClasses = {
  blueBase: `${colorClasses['blue']} ${sizeClasses['md']}`,
  outlineWhiteBase: `${colorClasses['outline-white']} ${sizeClasses['md']}`,
};

const CustomButton = (props: Props) => {
  const {
    disabled = false,
    className,
    buttonType = 'blueBase',
    onClick,
    leftIcon,
    rightIcon,
    children,
  } = props;
  const classes = `${className} ${buttonTypeClasses[buttonType]} inline-flex flex-row items-center justify-center gap-x-2 rounded-full border border-transparent disabled:pointer-events-none `;
  return (
    <button type="button" className={classes} onClick={onClick} disabled={disabled}>
      {leftIcon && <span>{leftIcon}</span>}
      <span>{children}</span>
      {rightIcon && <span>{rightIcon}</span>}
    </button>
  );
};

export default CustomButton;
